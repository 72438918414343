<template>
  <div id="print_area">

    <div id="print_target">
      <div id="print_content" class="attendance" ref="print_content">
        <img src="@/statics/img/居家-上課證明-A4.jpg" alt="">
        <div class="content_box">
          <p><span class="realname">
            {{ attendInfo.user_name }}</span> 君（身分證號：{{ attendInfo.id_number }}）
          </p>
          <p>
            於民國{{ year }}年{{ month }}月{{ day }}日，參加本醫學會舉辦之「{{ attendInfo.name }}」課程，共計 {{ attendInfo.hours }} 小時
          </p>
          <p class="proof_statement">特此證明</p>
        </div>
        <div class="date_box">
          <div class="year"><p>{{ year }}</p></div>
          <div class="month"><p>{{ month }}</p></div>
          <div class="day"><p>{{ day }}</p></div>
        </div>
      </div>
    </div>

    <div class="print_btn txt-center noprint">
      <button class="btn main_font" @click="printTarget">下載</button>
    </div>

  </div>
</template>

<script>
import html2pdf from 'html2pdf.js';

export default {
  name: 'attendancePDF',
  data() {
    return {
      attendInfo: {},
    };
  },
  created() {
    this.attendInfo = this.$cookies.get('attendInfo');
  },
  computed: {
    year() {
      return this.attendInfo.create_time.split(' ')[0].split('-')[0] * 1 - 1911;
    },
    month() {
      return this.attendInfo.create_time.split(' ')[0].split('-')[1];
    },
    day() {
      return this.attendInfo.create_time.split(' ')[0].split('-')[2];
    },
  },
  methods: {
    printTarget() {
      // window.print();

      window.scrollTo(0, 0);
      const opt = {
        margin: 0,
        filename: '上課證明',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'landscape',
          compress: false,
        },
      };
      html2pdf().from(this.$refs.print_content).set(opt).save();
    },
    // exportToPDF() {
    //   const opt = {
    //     margin: [0.5, 0, 0.5, 0],
    //     filename: '上課證明.pdf',
    //     image: { type: 'jpeg', quality: 0.98 },
    //     html2canvas: { dpi: 192, letterRendering: true },
    //     jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' },
    //   };
    //   // html2pdf(this.$refs.pdf_attendance, opt);
    //   html2pdf().from(this.$refs.pdf_attendance).set(opt).save();
    // },
  },
};
</script>

<style lang="scss" >
@import url("https://fonts.googleapis.com/earlyaccess/cwtexkai.css");

header, footer {
  display: none !important;
}
body {
  padding-top: 0;
  font-family: '標楷體', 'DFKai-SB', 'BiauKai', 'STKaiti', 'cwTeXKai', serif !important;
  background-color: rgba(gray, .1);
}
/** 包含此class的對象將從預覽列印中移除 */
@media print {
  .noprint {
    display: none;
  }
}
/** 消除預覽列印時的多餘邊界 */
@page {
  margin: 0cm;
}
</style>
